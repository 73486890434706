import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

Vue.use(Router);

// 解决路由两次点击报错
const VueRouterPush = Router.prototype.push;
Router.prototype.push = function push(to) {
    return VueRouterPush.call(this, to)
}
// 懒加载路由
const Home = () => import("./views/App.vue");
const Test = () => import("./views/Test.vue");

const SignIn = () => import("./views/login/SignIn.vue");
const PassList = () => import("./views/pass/PassList.vue");
const NoPage = () => import("./views/404/404.vue");

const router = new Router({
    mode: 'hash',
    linkExactActiveClass: 'is-active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        { path: '*', redirect: '/signin/:sid' },

        { path: '/signin/:sid', name: '/signin', component: SignIn },
        { path: '/pass/:sid/:cid', component: PassList },
        { path: '/404', component: NoPage },
    ]
});

router.beforeEach(async (to, from, next) => {
    //to prevent loading animation keeps running when switching router
    store.commit('app/APP_SET_IF_LOADING', { is_loading: false });
    next();
});

export default router;
