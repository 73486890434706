import fetchApi from '../../kits/newFetch';
const debugHost = 'http://127.0.0.1:7171';

// initial state
const state = {
    isValid: false,
};

// getters
const getters = {
    getIsValid(state) {
        return state.isValid;
    },
}

// actions
const actions = {
    triggerSms({ commit, rootState, rootGetters }, postBody) {
        let aj_host = rootState.debug ? debugHost : '';
        fetchApi(
            { commit },
            {
                request_name: 'trigger_sms',
                params: postBody.data,
                aj_host,
                sid: postBody.data.sid
            }
        )
            .then((res) => {
            }).catch((e) => {
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    verifySms({ commit, rootState, rootGetters }, postBody) {
        let aj_host = rootState.debug ? debugHost : '';
        fetchApi(
            { commit },
            {
                request_name: 'verify_sms',
                params: postBody.data,
                aj_host,
                sid: postBody.data.sid
            }
        )
            .then((res) => {
                let isValid = res && res.data && res.data.data && res.data.data.success;
                commit('SET_IS_VALID', { isValid });
                if (postBody.success) postBody.success({ isValid });
            }).catch((e) => {
                commit('SET_IS_VALID', { isValid: false });
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    }
};

// mutations
const mutations = {
    SET_IS_VALID(state, _data) {
        state.isValid = _data.isValid;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
