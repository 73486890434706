import fetchApi from '../../kits/newFetch';
import axios from 'axios'

const debugHost = 'http://127.0.0.1:7171';

const regExp1 = /^[0-3]{1}[0-9]{1}\-[a-zA-Z]{3}\-[0-9]{4}$/;
const monthToNum = {"Jan" : "01", "Feb" : "02", "Mar" : "03", "Apr" : "04", "May" : "05", "Jun" : "06", "Jul" : "07", "Aug" : "08", "Sep" : "09", "Oct" : "10", "Nov" : "11", "Dec" : "12"};
const amPmMap = {"A" : "AM", "P" : "PM"};

//29-Nov-2021, 8:24:21P
const formatDateTime = (date, time) => {
    let res = '';
    if(date) {
        const date_list = date.split('-');
        if(date_list && date_list.length == 3) {
            const month = monthToNum[date_list[1]];
            res += `${date_list[2]}-${month}-${date_list[0]}`;
        }
    }
    if(time && time.length > 0) {
        let am_pm;
        if((/[a-zA-Z]/).test(time.substr(-1))) {
            am_pm = amPmMap[time.substr(-1)];
        }
        time.replace(/([a-zA-Z])/g, "")
        res += ` ${time} ${am_pm}`;
    }
    return res;

}

const parseReceiptData = (data) => {
    if (data.ExpenseDocuments && data.ExpenseDocuments.length > 0) {
        const list = data.ExpenseDocuments[0].SummaryFields;
        let res = {};
        if (list && list.length > 0) {
            // console.log(list)
            for (let i = 0; i < list.length; ++i) {
                if (list[i].Type && list[i].Type.Text) {
                    if (list[i].Type.Text == 'VENDOR_NAME' && list[i].ValueDetection.Text) {
                        res['sname'] = list[i].ValueDetection.Text.replace(/\n/g, ' ');
                    }
                    if (list[i].Type.Text == 'TOTAL' && list[i].ValueDetection.Text) {
                        res['total'] = list[i].ValueDetection.Text.replace(/[^\d.]/g, '');
                    }
                    //invoive receipt id can be "Transaction", and/or "Order" and/or "check"
                    //"Transcation" can be "Type" or "LabelDetection"
                    if (list[i].Type.Text == 'INVOICE_RECEIPT_ID' && list[i].ValueDetection.Text) {
                        if(list[i].LabelDetection.Text == "Transaction") {
                           res['transaction_id'] = list[i].ValueDetection.Text.replace(/[^a-zA-Z0-9_-]/g, '');
                        }                    
                    }
                    if (list[i].Type.Text == 'TAX' && list[i].ValueDetection.Text) {
                        res['tax'] = list[i].ValueDetection.Text.replace(/[^\d.]/g, '');
                    }
                    if(list[i].Type.Text == 'INVOICE_RECEIPT_ID' && list[i].ValueDetection.Text) {
                        if(list[i].LabelDetection.Text == "Check") {
                            res['receipt_number'] = '#' + list[i].ValueDetection.Text.replace(/[^a-zA-Z0-9_-]/g, '');
                        }
                    }
                }
                if (list[i].LabelDetection && list[i].LabelDetection.Text) {
                    if (list[i].LabelDetection.Text == 'Table') {
                        res['table_id'] = list[i].ValueDetection.Text;
                    }
                    //may not have time or check number
                    if(!res.time_on_receipt && regExp1.test(list[i].LabelDetection.Text)) {//29-Nov-2021, 8:24:21P
                        res['time_on_receipt'] = formatDateTime(list[i].LabelDetection.Text, list[i].ValueDetection.Text);
                    }
                    //if no transaction_id, use order id as transcation id
                    if(!res.transaction_id && list[i].Type.Text == 'INVOICE_RECEIPT_ID' && list[i].ValueDetection.Text) {
                        if(list[i].LabelDetection.Text == "Order") {
                            res['transaction_id'] = '#' + list[i].ValueDetection.Text.replace(/[^a-zA-Z0-9_-]/g, '');
                        }
                    }
                }
            }
        }
        // console.log(res)
        return res;
    }
}

// initial state
const state = {
    sInfo: {
        sname: '',
        slogo: '',
        sdesc: '',
        spic: '',
        saddress: ''
    },
    user: {},
    is_loading: false,
    errMsg: null
};

// getters
const getters = {
    getsInfo(state) {
        return state.sInfo;
    },
    getUser(state) {
        return state.user;
    },
    getLoading(state) {
        return state.is_loading;
    },
    getMsg(state) {
        return state.errMsg;
    }
};

// actions
const actions = {
    initsInfo({ commit, rootState, rootGetters }, { postBody, router }) {
        let aj_host = rootState.debug ? debugHost : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_sinfo',
                params: postBody.data,
                aj_host,
                sid: postBody.data.sid
            }
        )
            .then((res) => {
                if (res && res.data && res.data.data) {
                    if (postBody.success) postBody.success();
                    let vo = res.data.data;
                    if(vo.pass_enable==0){ 
                        router.replace({path: '/404'});
                    }
                    commit('SET_SINFO', {
                        sInfo:
                        {
                            sname: vo.sname || '',
                            slogo: vo.slogo || '/',
                            sdesc: vo.sdesc || '',
                            spic: vo.spic || '',
                            saddress: vo.saddress || '',
                            sid: postBody.data.sid,
                            stel: vo.stel,
                            street_address: vo.street_address,
                            locality: vo.locality,
                            postal_code: vo.postal_code,
                            receipt_enable: vo.receipt_enable || 0,
                            receipt_limit_perday: vo.receipt_limit_perday,
                            receipt_convert_rate: vo.receipt_convert_rate
                        }
                    });
                } else {
                    console.log(res);
                    if (postBody.fail) postBody.fail(e);
                }
            }).catch((e) => {
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    initLoyaltyUserInfo({ commit, rootState, rootGetters }, postBody) {
        commit('APP_SET_IF_LOADING', { is_loading: true });
        let aj_host = rootState.debug ? debugHost : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_user_info',
                params: postBody.data,
                aj_host,
                sid: postBody.data.sid
            })
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (res && res.data && res.data.data) {
                    let user = { ...res.data.data, home: true };
                    commit('SET_USER', { user, sid: postBody.data.sid });
                    if (postBody.success) postBody.success({ ...user, sId: postBody.data.sid });
                }
            }).catch((e) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    submitUser({ commit, rootState, rootGetters }, postBody) {
        commit('APP_SET_IF_LOADING', { is_loading: true });
        let aj_host = rootState.debug ? debugHost : '';
        fetchApi(
            { commit },
            {
                request_name: 'update_user_info',
                params: postBody.data,
                aj_host,
                sid: postBody.data.sid
            })
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (res && res.data && res.data.data && res.data.code === 100000) {
                    let user = { ...res.data.data };
                    commit('SET_USER', { user, sid: postBody.data.sid });
                    if (postBody.success) postBody.success({ ...user, sid: postBody.data.sid, cid: user.id });
                }
            }).catch((e) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    verifyUser({ commit, rootState, rootGetters }, postBody) {
        commit('APP_SET_IF_LOADING', { is_loading: true });
        let aj_host = rootState.debug ? debugHost : '';
        fetchApi(
            { commit },
            {
                request_name: 'verify_user',
                params: postBody.data,
                aj_host,
                sid: postBody.data.sid
            })
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (res && res.data && res.data.data && res.data.code === 100000) {
                    let user = { ...res.data.data[0] };
                    commit('SET_USER', { user, sid: postBody.data.sid });
                    if (postBody.success) postBody.success({ ...user, sid: postBody.data.sid, cid: user.id });
                }
            }).catch((e) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    initCardDetail({ commit, rootState, rootGetters }, postBody) {
        let aj_host = rootState.debug ? debugHost : '';
        commit('APP_SET_IF_LOADING', { is_loading: true });
        fetchApi(
            { commit },
            {
                request_name: 'get_loyalty_detail',
                params: postBody.data,
                aj_host,
                sid: postBody.data.sid
            })
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.success) postBody.success({ item: res.data.data && res.data.data[0] ? res.data.data[0] : {} });
            }).catch((e) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.fail) postBody.fail(e);
            });
    },
    initOrderHList({ commit, rootState, rootGetters }, postBody) {
        let aj_host = rootState.debug ? debugHost : '';
        commit('APP_SET_IF_LOADING', { is_loading: true });
        fetchApi(
            { commit },
            {
                request_name: 'get_loyalty_log',
                params: postBody.data,
                aj_host,
                sid: postBody.data.sid
            })
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.success) postBody.success({ list: res && res.data && res.data.data ? res.data.data : [] });
            }).catch((e) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    analyzeReceipt({ commit, rootState, rootGetters }, postBody) {
        let aj_host = rootState.debug ? debugHost : '';
        commit('APP_SET_IF_LOADING', { is_loading: true });
        fetchApi(
            { commit },
            {
                request_name: 'analyze_receipt',
                params: postBody.data,
                aj_host,
                sid: postBody.data.sid
            })
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.success) postBody.success({ data: res && res.data && res.data.data && res.data.data.data ? parseReceiptData(res.data.data.data) : {} });
            }).catch((e) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    uploadReceipt({ commit, rootState, rootGetters }, postBody) {
        let aj_host = rootState.debug ? debugHost : '';
        commit('APP_SET_IF_LOADING', { is_loading: true });
        fetchApi(
            { commit },
            {
                request_name: 'upload_receipt',
                params: postBody.data,
                aj_host,
                sid: postBody.data.sid
            })
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.success) postBody.success({ data: res && res.data && res.data.data ? res.data.data : {} });
            }).catch((e) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    verifyReceipt({ commit, rootState, rootGetters }, postBody) {
        let aj_host = rootState.debug ? debugHost : '';
        console.log(postBody)
        commit('APP_SET_IF_LOADING', { is_loading: true });
        fetchApi(
            { commit },
            {
                request_name: 'verify_receipt',
                params: postBody.data,
                aj_host,
                sid: postBody.data.sid
            })
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.success) postBody.success({ data: res && res.data && res.data.data ? res.data.data : {} });
            }).catch((e) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    createReceipt({ commit, rootState, rootGetters }, postBody) {
        let aj_host = rootState.debug ? debugHost : '';
        commit('APP_SET_IF_LOADING', { is_loading: true });
        fetchApi(
            { commit },
            {
                request_name: 'create_receipt',
                params: postBody.data,
                aj_host,
                sid: postBody.data.sid
            })
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.success) postBody.success();
            }).catch((e) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    reviseReceipt({ commit, rootState, rootGetters }, postBody) {
        let aj_host = rootState.debug ? debugHost : '';
        commit('APP_SET_IF_LOADING', { is_loading: true });
        fetchApi(
            { commit },
            {
                request_name: 'revise_receipt',
                params: postBody.data,
                aj_host,
                sid: postBody.data.sid
            })
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.success) postBody.success();
            }).catch((e) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },
    createPotentialPoint({ commit, rootState, rootGetters }, postBody) {
        let aj_host = rootState.debug ? debugHost : '';
        commit('APP_SET_IF_LOADING', { is_loading: true });
        fetchApi(
            { commit },
            {
                request_name: 'create_potential_point',
                params: postBody.data,
                aj_host,
                sid: postBody.data.sid
            })
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.success) postBody.success({ data: res && res.data && res.data.data && res.data.data ? res.data.data : {} });
            }).catch((e) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                if (postBody.fail) postBody.fail(e);
                console.log(e);
            });
    },

    getApplePass({ commit, rootState, rootGetters }, params) {
        let aj_host = rootState.debug ? debugHost : '';
        commit('APP_SET_IF_LOADING', { is_loading: true });
        const token = window.localStorage.getItem('token_' + params.data.sid) || '';

        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: aj_host + '/weapp/loyalty/pass/getPass',
                data: params.data,
                responseType: 'blob',

                headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + token },
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
};

// mutations
const mutations = {
    APP_SET_IF_LOADING(state, _data) {
        state.is_loading = _data.is_loading;
    },
    APP_SET_MSG(state, _data) {
        // state.errMsg = _data.msg;
        let msgObj = null;
        try {
            msgObj = JSON.parse(_data.msg);
        } catch {
            msgObj = {};
        }

        if (msgObj && msgObj.msg) {
            state.errMsg = msgObj.msg;
        } else {
            state.errMsg = _data.msg;
        }
    },
    SET_SINFO(state, _data) {
        state.sInfo = _data.sInfo;
        // window.localStorage.setItem('sid', JSON.stringify(_data.sInfo.sid));
    },
    SET_USER(state, _data) { // 更新用户登录状态
        let newData = _data.user ? _data.user : { ...state.user, home: !!_data.home };
        state.user = newData;
        window.localStorage.setItem('user_' + _data.sid, JSON.stringify(newData));
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
